.documentation-category{
    padding: 0px 0px 20px;
    /* border-top: solid 1px var(--border-subtle-00,#e0e0e0); */
    /* border-bottom: solid 1px #F0F0F0; */
}

.documentation-category .seperator-wrapper{
    display: none;
}

.documentation-category + .documentation-category .seperator-wrapper{
    display: grid;
    grid-template-columns: repeat(auto-fill, 288px);
    column-gap: 12px;
}

.documentation-category + .documentation-category hr{
    grid-column: 1/-1;
    display: block;
    margin-bottom: 16px;
    border: none;
    border-top: solid 1px var(--border-subtle-00,#e0e0e0);
}

.documentation-category ul{
    width: 100%;
    
    display: grid;
    grid-template-columns: repeat(auto-fill, 288px);
    grid-template-rows: 144px;
    column-gap: 12px;
    row-gap: 12px;
    
    padding: 20px 0px;
    padding-top: 0px;
    justify-content: flex-start;

    /* max-width: min(1000px, 100%); */
    max-width: 100%;
}

.documentation-category ul.empty{
    display: block;
}

.documentation-category .empty-text{
    font-style: italic;
    color: var(--text-placeholder, #a8a8a8);
}

.documentation-category ul li{
    list-style: none;
}

.documentation-category .documentation-category-title {
    font-weight: normal;
    font-size: 20px;
    padding-bottom: 16px;
    padding-top: 6px;
}

.documentation-category + .documentation-category .documentation-category-title{
    padding: 16px 0px;
}
.tooltip-wrapper {
    position: relative;
}

.tooltiptext {
    font-size: 14px;
    line-height: 18px;
    color: white;
    background-color: var(--background-inverse,#393939);

    position: absolute;
    opacity: 1;
    z-index: 1005;

    padding: 16px;
    min-height: 22px;
    min-width: 105px;
    max-width: 240px;
    width: max-content;
    border-radius: 2px;

    top: 0px;
    left: 0px;

    /* display: none; */
}

.tooltiptext::after {
    content: "";
    position: absolute;
    margin-top: -6px;
    border-width: 12px;
    border-style: solid;
    border-color: transparent var(--background-inverse,#393939) transparent transparent;
}

.tooltiptext[position="top"]{
    transform: translateY(calc(-100% - 16px));
}

.tooltiptext[position="bottom"]{
    transform: translateY(16px);
}

.tooltiptext[position="left"]{
    transform: translateX(calc(-100% - 16px));
}

.tooltiptext[position="right"]{
    transform: translateX(16px);
}

.tooltiptext[position='bottom'][pointer='start']::after {
    top: -16px;
    left: 16px;
    transform: rotateZ(90deg);
}

.tooltiptext[position='bottom'][pointer=middle]::after {
    top: -16px;
    left: 50%;
    transform: rotateZ(90deg) translateY(50%);
}

.tooltiptext[position='bottom'][pointer='end']::after {
    top: -16px;
    left: calc(100% - 16px);
    transform: rotateZ(90deg) translateY(100%);
}

.tooltiptext[position='top'][pointer='start']::after {
    top: calc(100% + 5px);
    left: 16px;
    transform: rotateZ(-90deg);
}

.tooltiptext[position='top'][pointer=middle]::after {
    top: calc(100% + 5px);
    left: 50%;
    transform: rotateZ(-90deg) translateY(-50%);
}

.tooltiptext[position='top'][pointer='end']::after {
    top: calc(100% + 5px);
    right: 16px;
    transform: rotateZ(-90deg);
}

.tooltiptext[position='right'][pointer=start]::after {
    top: calc(19px + 8px);
    left: -11px;
    transform: translateY(-50%) translateX(-50%);
}

.tooltiptext[position='right'][pointer=middle]::after {
    top: calc(50% + 8px);
    left: -11px;
    transform: translateY(-50%) translateX(-50%);
}

.tooltiptext[position='right'][pointer=end]::after {
    top: calc(100% - 14px);
    left: -11px;
    transform: translateY(-50%) translateX(-50%);
}

.tooltiptext[position='left'][pointer=start]::after {
    top: 14px;
    right: -23px;
    transform: rotate(180deg);
}

.tooltiptext[position='left'][pointer=middle]::after {
    top: calc(50% - 5px);
    right: -35px;
    transform: translateX(-50%) rotate(180deg);
}

.tooltiptext[position='left'][pointer=end]::after {
    top: calc(100% - 26px);
    right: -35px;
    transform: translateX(-50%) rotate(180deg);
}
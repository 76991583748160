#search-results-placeholder{
    display: none !important;
}

form.gsc-search-box{
    margin-bottom: 0px !important;
}

input#gsc-i-id1 {
    background: transparent;
}

input#gsc-i-id1::placeholder{
    font-weight: 300;
}

.gsst_a {
    cursor: pointer;
    padding: 4px !important;
    text-decoration: none!important;
    width: 24px;
    height: 24px;
    align-items: center;
    justify-content: center;
    margin-right: -16px;
}

.gsst_a .gscb_a {
    color: var(--icon-secondary,#525252) !important;
    height: 16px;
    width: 16px;
    display: -webkit-flex;
    display: flex !important;
    align-items: center;
    justify-content: center;
    margin-top: 1px;
}

#landing-banner .gsst_a {
    margin-right: 0px;
    margin-top: -2px;
}

#landing-banner .gsst_a .gscb_a {
    margin-top: 0px;
}

.gsst_a:hover{
    background-color: var(--background-hover, rgb(141, 141, 141, 12%));
}

.gsst_a:focus{
    outline: solid var(--border-interactive, #0f62fe) 2px;
    outline-offset: -2px;
}
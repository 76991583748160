:root{
  /* Delite Colors */
  --dlt-color-global-primary-default: #058192;  /* Primary color */                 
  --dlt-color-global-primary-hover: ;  /* Primary color */                 
  --dlt-color-global-primary-highlighted:  #1c5569; /* Darker primary color */
  --dlt-color-global-secondary-default: #ffffff; /* Secondary color */ 
  --dlt-color-global-secondary-hover: #ebf9f8; /* Secondary color */ 
  --dlt-color-global-secondary-highlighted: #c5edea; /* Lighter primary color */
  --dlt-color-global-accent-default:#8e3cf7; /* Emphasis color */
  --dlt-color-global-accent-hover:#712dd2; /* Emphasis color */
  --dlt-color-global-accent-subtle:#f1e7fe; /* Emphasis color */
  --dlt-color-global-accent-highlighted: #3b2c5e; /* Darker emphasis color */
  --dlt-color-global-gradient-start:#8e3cf7; /* Emphasis color */
  --dlt-color-global-gradient-end: #058192; /* Darker emphasis color */
  
  --dlt-color-system-message-warning: #e07800;
  --dlt-color-system-message-error: #c72d56;
  --dlt-color-system-message-info: #1776bf;
  --dlt-color-system-message-success: #71a112;

  /* All the following ones are grey shades */
  --dlt-color-global-neutral-980: #ffffff;
  --dlt-color-global-neutral-900: #f9fafb;
  --dlt-color-global-neutral-800: #f0f2f4;
  --dlt-color-global-neutral-700: #e7ebee;
  --dlt-color-global-neutral-600: #d2d9e0;
  --dlt-color-global-neutral-400: #537393;
  --dlt-color-global-neutral-300: #36597d;
  --dlt-color-global-neutral-100: #011f3d; /* Core dark */

  /* Border Radius */
  --dlt-border-radii-default: 0px;
}

:root{
  /* Carbon Tokens */
  --background:#ffffff;
  --background-hover:rgba(141, 141, 141, 12%);
  --background-active:rgba(141, 141, 141, 50%);
  --background-selected:rgb(141, 141, 141, 20%);
  --background-selected-hover:rgb(141, 141, 141, 32%);
  --background-inverse:#393939;
  --background-inverse-hover:#4c4c4c;
  --background-brand:#0f62fe;

  /* Layer */
  --layer-01:#f4f4f4;
  --layer-02:#ffffff;
  --layer-03:#f4f4f4;
  --layer-hover-01:#e8e8e8;
  --layer-hover-02:#e8e8e8;
  --layer-hover-03:#e8e8e8;
  --layer-active-01:#c6c6c6;
  --layer-active-02:#c6c6c6;
  --layer-active-03:#c6c6c6;
  --layer-selected-01:#e0e0e0;
  --layer-selected-02:#e0e0e0;
  --layer-selected-03:#e0e0e0;
  --layer-selected-hover-01:#cacaca;
  --layer-selected-hover-02:#cacaca;
  --layer-selected-hover-03:#cacaca;
  --layer-selected-inverse:#161616;
  --layer-selected-disabled:#8d8d8d;

  /* Layer accent */
  --layer-accent-01:#e0e0e0;
  --layer-accent-02:#e0e0e0;
  --layer-accent-03:#e0e0e0;
  --layer-accent-hover-01:#cacaca;
  --layer-accent-hover-02:#cacaca;
  --layer-accent-hover-03:#cacaca;
  --layer-accent-active-01:#a8a8a8;
  --layer-accent-active-02:#a8a8a8;
  --layer-accent-active-03:#a8a8a8;

  /* Field */
  --field-01:#f4f4f4;
  --field-02:#ffffff;
  --field-03:#f4f4f4;
  --field-hover-01:#e8e8e8;
  --field-hover-02:#e8e8e8;
  --field-hover-03:#e8e8e8;

  /* Border */
  --border-interactive:#0f62fe;
  --border-subtle-00:#e0e0e0;
  --border-subtle-01:#c6c6c6;
  --border-subtle-02:#e0e0e0;
  --border-subtle-03:#c6c6c6;
  --border-subtle-selected-01:#c6c6c6;
  --border-subtle-selected-02:#c6c6c6;
  --border-subtle-selected-03:#c6c6c6;
  --border-strong-01:#8d8d8d;
  --border-strong-02:#8d8d8d;
  --border-strong-03:#8d8d8d;
  --border-tile-01:#c6c6c6;
  --border-tile-02:#a8a8a8;
  --border-tile-03:#c6c6c6;
  --border-inverse:#161616;
  --border-disabled:#c6c6c6;

  /* Text */
  --text-primary:#161616;
  --text-secondary:#525252;
  --text-placeholder:#a8a8a8;
  --text-on-color:#ffffff;
  --text-on-color-disabled:#8d8d8d;
  --text-helper:#6f6f6f;
  --text-error:#da1e28;
  --text-inverse:#ffffff;
  --text-disabled:rgba(22, 22, 22, 25%);

  /* Link */
  --link-primary:#0f62fe;
  --link-primary-hover:#0043ce;
  --link-secondary:#0043ce;
  --link-inverse:#78a9ff;
  --link-inverse-hover:#a6c8ff;
  --link-inverse-active:#f4f4f4;
  --link-inverse-visited:#be95ff;
  --link-visited:#8a3ffc;

  /* Icon */
  --icon-primary:#161616;
  --icon-secondary:#525252;
  --icon-on-color:#ffffff;
  --icon-on-color-disabled:#8d8d8d;
  --icon-interactive:#0f62fe;
  --icon-inverse:#ffffff;
  --icon-disabled:#161616 – 25%;

  /* Button */
  --button-primary:#0f62fe;
  --button-primary-hover:#0353e9;
  --button-primary-active:#002d9c;
  --button-secondary:#393939;
  --button-secondary-hover:#4c4c4c;
  --button-secondary-active:#6f6f6f;
  --button-tertiary:#0f62fe;
  --button-tertiary-hover:#0353e9;
  --button-tertiary-active:#002d9c;
  --button-danger-primary:#da1e28;
  --button-danger-secondary:#da1e28;
  --button-danger-hover:#ba1b23;
  --button-danger-active:#750e13;
  --button-separator:#e0e0e0;
  --button-disabled:#c6c6c6;

  /* Support */
  --support-error:#da1e28;
  --support-success:#24a148;
  --support-warning:#f1c21b;
  --support-info:#0043ce;
  --support-error-inverse:#fa4d56;
  --support-success-inverse:#42be65;
  --support-warning-inverse:#f1c21b;
  --support-info-inverse:#4589ff;

  /* Focus */
  --focus:#0f62fe;
  --focus-inset:#ffffff;
  --focus-inverse:#ffffff;

  /* Miscellaneous */
  --interactive:#0f62fe;
  --highlight:#d0e2ff;
  --toggle-off:#8d8d8d;
  --overlay:#161616 50%;
  --skeleton-element:#c6c6c6;
  --skeleton-background:#e5e5e5;

  /* Tag */
  --tag-background-gray:#e0e0e0;
  --tag-color-gray:#161616;
  --tag-hover-gray:#d1d1d1;
  --tag-border-gray:#a8a8a8;
  --tag-background-cool-gray:#dde1e6;
  --tag-color-cool-gray:#121619;
  --tag-hover-cool-gray:#cdd3da;
  --tag-border-cool-gray:#a2a9b0;
  --tag-background-warm-gray:#e5e0df;
  --tag-color-warm-gray:#171414;
  --tag-hover-warm-gray:#d8d0cf;
  --tag-border-warm-gray:#ada8a8;
  --tag-background-red:#ffd7d9;
  --tag-color-red:#a2191f;
  --tag-hover-red:#ffc2c5;
  --tag-border-red:#ff8389;
  --tag-background-magenta:#ffd6e8;
  --tag-color-magenta:#9f1853;
  --tag-hover-magenta:#ffbdda;
  --tag-border-magenta:#ff7eb6;
  --tag-background-purple:#e8daff;
  --tag-color-purple:#6929c4;
  --tag-hover-purple:#dcc7ff;
  --tag-border-purple:#be95ff;
  --tag-background-blue:#d0e2ff;
  --tag-color-blue:#0043ce;
  --tag-hover-blue:#b8d3ff;
  --tag-border-blue:#78a9ff;
  --tag-background-cyan:#bae6ff;
  --tag-color-cyan:#00539a;
  --tag-hover-cyan:#99daff;
  --tag-border-cyan:#33b1ff;
  --tag-background-teal:#9ef0f0;
  --tag-color-teal:#005d5d;
  --tag-hover-teal:#57e5e5;
  --tag-border-teal:#08bdba;
  --tag-background-green:#a7f0ba;
  --tag-color-green:#0e6027;
  --tag-hover-green:#74e792;
  --tag-border-green:#42be65;

  /* AI */
  --ai-aura-start:#4589ff 10%;
  --ai-aura-start-sm:#4589ff 16%;
  --ai-aura-end:#ffffff 0%;
  --ai-aura-hover-start:#4589ff;
  --ai-aura-hover-end:#ffffff 0%;
  --ai-aura-hover-background:#edf5ff;
  --ai-border-start:#a6c8ff 64%;
  --ai-border-end:#78a9ff;
  --ai-border-strong:#4589ff;
  --ai-drop-shadow:rgba(15, 98, 254, 10%);
  --ai-inner-shadow:rgba(69, 137, 255, 10%);
  --ai-popover-background:#ffffff;
  --ai-popover-shadow-outer-01:rgba(0, 67, 206, 6%);
  --ai-popover-shadow-outer-02:rgba(0, 0, 0, 4%);
  --ai-skeleton-element:#4589ff;
  --ai-skeleton-background:#d0e2ff;
  --ai-overlay:#001141 50%;

  /* Chat */
  --chat-avatar-bot:#6f6f6f;
  --chat-avatar-agent:#393939;
  --chat-avatar-user:#0f62fe;
  --chat-bubble-user:#e0e0e0;
  --chat-bubble-agent:#ffffff;
  --chat-bubble-border:#e0e0e0;
  --chat-prompt-background:#ffffff;
  --chat-prompt-border-start:#f4f4f4;
  --chat-prompt-border-end:rgba(244, 244, 244,0%);
  --chat-shell-background:#ffffff;
  --chat-header-background:#ffffff;

  /* Chat button */
  --chat-button:#0f62fe;
  --chat-button-hover:rgba(141, 141, 141,12%);
  --chat-button-text-hover:#0043ce;
  --chat-button-active:rgba(141, 141, 141,50%);
  --chat-button-selected:rgba(141, 141, 141, 20%);
  --chat-button-text-selected:#525252;

  /* Selectable Tags */
  --tag-selectable-tag-background: #F4F4F4;
  --tag-selectable-tag-background-hover: #E8E8E8;
  --tag-selectable-tag-background-selected: #161616;

  --tag-selectable-tag-color: #ffffff;
}

:root{
  /* Custom variables */
  /* --accent-color-primary: var(--dlt-color-global-accent-default);
  --accent-color-subtle: var(--dlt-color-global-accent-subtle); */

  --accent-color-primary: var(--tag-color-blue, #0043ce);
  --accent-color-subtle: var(--tag-background-blue, #d0e2ff);

  scroll-padding-top: 205px;
  scroll-behavior: smooth;
}

/* IBM standard b. original ver   bb.ibm-test.com.tmpl */
@import url("https://1.www.s81c.com/common/carbon/plex/sans-hebrew.css?family=IBM+Plex+Sans+Hebrew:wght@400;600&display=swap");
@import url("https://1.www.s81c.com/common/carbon/plex/sans-arabic.css?family=IBM+Plex+Sans+Arabic:wght@400;600&display=swap");
@import url("https://1.www.s81c.com/common/carbon/plex/sans-jp.css?family=IBM+Plex+Sans+JP:wght@400;600&display=swap");
@import url("https://1.www.s81c.com/common/carbon/plex/sans-kr.css?family=IBM+Plex+Sans+KR:wght@400;600&display=swap");

@font-face {
  font-family: "IBM Plex Sans";
  src: url(https://consent.trustarc.com/get?name=IBM_PlexSans-Regular.ttf) format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "IBM Plex Sans";
  src: url(https://consent.trustarc.com/get?name=IBM_PlexSans-SemiBold.ttf) format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-ThinItalic.ttf') format('truetype');
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-LightItalic.ttf') format('truetype');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-MediumItalic.ttf') format('truetype');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('./assets/fonts/Roboto-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

*,*::after,*::before{
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  /* font-family: "IBM Plex Sans"; */
   /* -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-primary,#161616);
  background-color: var(--layer-01, #f4f4f4);
  overflow: visible;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Button Styling */
.button-primary{
  -o-border-radius: 0px;
  -ms-border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;

  background: var(--button-primary,#0f62fe);
  color: var(--text-on-color, #ffffff);

  border: none;
}

.button-primary:hover{
  background: var(--button-primary-hover, #0353e9);
}

.button-primary:active{
  background: var(--button-primary-active, #002d9c);
}

.button-primary:focus,
.button-primary:focus-visible,
.button-primary:focus-within{
  outline: solid var(--focus, #0f62fe) 2px;
  outline-offset: 1px;
}

.button-tertiary{
  -o-border-radius: 0px;
  -ms-border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  border-radius: 0px;

  background: transparent;
  color: var(--button-tertiary, #0f62fe);
  border: solid var(--button-tertiary, #0f62fe) 1px;
}

.button-tertiary:hover{
  color: var(--text-on-color, #ffffff);
  background: var(--button-tertiary-hover, #0353e9);
}

.button-tertiary:active{
  color: var(--text-on-color, #ffffff);
  background: var(--button-tertiary-active, #002d9c);
}

.button-tertiary:focus,
.button-tertiary:focus-visible,
.button-tertiary:focus-within{
  outline: solid var(--focus, #0f62fe) 2px;
  outline-offset: 1px;
}

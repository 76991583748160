.result-item {
    background: var(--field-02, #ffffff);
    padding: 16px;
    margin-bottom: 8px;

    max-width: 100%;
}

.result-item .result-item-header a{
    display: block;
    color: var(--link-primary, #0f62fe);
    font-size: 16px;
    line-height: 18px;
    text-decoration: none;
    margin-bottom: 4px;
    cursor: pointer;

    text-wrap: pretty;
    white-space: break-spaces;
}

.result-item .result-item-header a:hover{
    text-decoration: underline;
    color: var(--link-primary-hover, #0043ce);
}

.result-item .result-item-metadata{
    display: block;
    color: var(--text-helper, #6f6f6f);
    font-size: 14px;
    margin: 8px 0px;
}

.result-item-description{
    font-size: 14px;
    line-height: 18px;
    padding: 8px 0px;

    text-wrap: pretty;
    white-space: break-spaces;
}

span.metadata-item + span.metadata-item::before{
    content: '/';
    margin-left: 4px;
    margin-right: 4px;
}

strong.metadata-type {
    margin-right: 4px;
}
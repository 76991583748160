.announcement-banner-wrappaer {
    width: 100%;
    background-color: var(--layer-accent-01, #e0e0e0);
    padding: 6px 16px;
    padding-right: 6px;
    font-size: 6px;

    display: flex;
    align-items: center;
    column-gap: 12px;
}

.announcement-banner-text{
    flex: 1;
    font-size: 14px;
    line-height: 22px;
    text-align: center;
}

.announcement-banner-text a,
.announcement-banner-text a:visited{
    color: var(--link-primary,#0f62fe);
}

.announcement-banner-close-btn{
    width: 24px;
    height: 24px;
    font-size: 22px;

    border: none;
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    -o-border-radius: 0px;

    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: transparent;
    cursor: pointer;
}

.announcement-banner-close-btn:hover{
    outline: solid var(--button-tertiary-hover, #0353e9) 1px;
    outline-offset: -2px;
}
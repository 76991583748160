.topic-card-link{
    text-decoration: none;
    color: inherit;
}

.topic-card-link:focus,
.topic-card-link:focus-visible {
    outline: solid var(--accent-color-primary, #0043ce) 2px;
    outline-offset: -2px;
}

.topic-card-link svg{
    fill: var(--accent-color-primary, #0043ce);
}

.topic-card {
    background-color: var(--background, #ffffff);
    border: solid 1px var(--border-subtle-01,#c6c6c6);
    color: var(--text-primary, #161616);
    padding: 16px;
    height: 144px;

    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -webkit-box-flex-direction: column;
    -moz-box-flex-direction: column;
    -ms-flex-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;

    -webkit-box-row-gap: 12px;
    -moz-box-row-gap: 12px;
    -ms-flex-row-gap: 12px;
    -webkit-row-gap: 12px;
    row-gap: 12px;

    position: relative;
}

.topic-card:hover::before {
	width: 288px; /* Expands the width to 100% on hover */
}


.topic-card::before {
	content: "";
	position: absolute;
	top: 0px;
	left: 50%;
	-webkit-transform: translate(-50%,-50%);
	transform: translate(-50%,-50%);
	width: 0;
	height: 2px;
	border-top: 2px;
	/* background: linear-gradient(99.14deg,#080051 6.93%,#d0e2ff 93.07%); */
    background: linear-gradient(99.14deg,#001141 6.93%,#4589ff 93.07%);
	-webkit-transition: width .2s;
	transition: width .2s;
	/* display: block; */
}
.topic-card-title {
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    font-weight: normal;
    
    color: var(--core-dark);
    margin: 0px;
    flex: 1;
}

.topic-card:focus,
.topic-card:focus-visible {
    outline: solid var(--accent-color-primary, #8e3cf7) 2px;
    outline-offset: -2px;
}

.topic-card:hover {
    background-color: #F0F2F4;
    cursor: pointer;
}

.topic-card-footer{
    display: -webkit-flex;
    display: flex;
    justify-content: space-between;
}

.topic-card-external-link{
    width: 100%;
}

.topic-card-external-link span{
    float: right;
}

.topic-card-external-link svg{
    fill: var(--link-primary, #0f62fe);
}

.topic-card-tags {
    display: flex;
    flex-wrap: wrap;
    row-gap: 5px;
    column-gap: 3px;
}

.tags-item {
    font-size: 10px;
    color: var(--tag-color-gray,#161616);
    background-color: var(--tag-background-gray, #e0e0e0);
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    display: inline-block;
    width: max-content;
    padding: 3px 10px 3.5px;
    display: inline;
}

.tags-item.colored-tag{
    color: var(--tag-color-blue,#0043ce);
    background-color: var(--tag-background-blue,#d0e2ff);
}

.tags-item.update-tag {
    background-color: #F1E7FE;
    color: #712DD2;
}

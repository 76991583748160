.capability-dropdown-wrapper  .dropdown-wrapper {
    padding: 0px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: max-content;
}

.capability-dropdown-wrapper .dropdown-label{
    font-size: 12px;
    margin-bottom: 6px;
}

.capability-dropdown-wrapper .dropdown-container{
    max-width: 288px;
    outline: 2px solid transparent;
    outline-offset: -2px;
    position: relative;
    display: block;
    border: none;
    background-color: var(--field-01,#f4f4f4);
    block-size: 2.5rem;
    -webkit-border-after: 1px solid var(--cds-border-strong);
    border-block-end: 1px solid var(--cds-border-strong);
    color: var(--cds-text-primary,#161616);
    cursor: pointer;
    inline-size: 100%;
    list-style: none;
    outline: 2px solid transparent;
    transition: background-color 70ms cubic-bezier(.2,0,.38,.9);
    height: max-content;
}

.capability-dropdown-wrapper .dropdown-container .list-box_field{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 6px 12px 8px 12px;
    justify-content: space-between;
    margin-top: 4px;
    border: none;
    border-bottom: 1px solid var(--border-strong-01, #8d8d8d);
    cursor: pointer;
    background-color: transparent;
    text-align: left;
    color: var(--text-primary, #161616);
}

.capability-dropdown-wrapper .dropdown-container .list-box_field span{
    color: var(--text-primary, #161616) !important;
}

.capability-dropdown-wrapper .dropdown-container:hover {
    background-color: #e8e8e8;
}

/* .dropdown-container .list-box_field:focus,
.dropdown-container .list-box_field:focus-visible{
    outline: 2px solid var(--cds-focus, #0f62fe);
    outline-offset: -2px;
} */

.capability-dropdown-wrapper .dropdown-container .list-box__menu {
    list-style: none;
    
    box-shadow: 0 2px 6px var(--cds-shadow,rgba(0,0,0,0.3));
    position: absolute;
    z-index: 9100;
    display: none;
    background-color: #f4f4f4;
    inline-size: 100%;
    inset-inline-end: 0;
    inset-inline-start: 0;
    overflow-y: auto;
    
    transition: max-height 110ms cubic-bezier(.2,0,.38,.9);
    -webkit-transition: max-height 110ms cubic-bezier(.2,0,.38,.9);
    -moz-transition: max-height 110ms cubic-bezier(.2,0,.38,.9);
    -ms-transition: max-height 110ms cubic-bezier(.2,0,.38,.9);
    -o-transition: max-height 110ms cubic-bezier(.2,0,.38,.9);
}

.capability-dropdown-wrapper .list-box--expanded .list-box__menu {
    display: block;
    max-block-size: 13.75rem;
}

.capability-dropdown-wrapper .list-box__menu-item {
    font-size: var(--cds-body-compact-01-font-size,0.875rem);
    font-weight: var(--cds-body-compact-01-font-weight,400);
    line-height: var(--cds-body-compact-01-line-height,1.28572);
    letter-spacing: var(--cds-body-compact-01-letter-spacing,0.16px);
    position: relative;
    block-size: 2.5rem;
    color: var(--cds-text-secondary,#525252);
    cursor: pointer;

    -o-transition: background 70ms cubic-bezier(.2,0,.38,.9);
    -ms-transition: background 70ms cubic-bezier(.2,0,.38,.9);
    -moz-transition: background 70ms cubic-bezier(.2,0,.38,.9);
    -webkit-transition: background 70ms cubic-bezier(.2,0,.38,.9);
    transition: background 70ms cubic-bezier(.2,0,.38,.9);

    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;

    height: max-content;
}

.capability-dropdown-wrapper .list-box__menu-item__option {
    box-sizing: border-box;
    padding: 0;
    border: 0;
    margin: 0;
    font-family: inherit;
    font-size: 100%;
    outline: 2px solid transparent;
    outline-offset: -2px;
    display: block;
    overflow: hidden;
    block-size: 2.5rem;
    -webkit-border-after: 1px solid transparent;
    border-block-end: 1px solid transparent;
    -webkit-border-before: 1px solid transparent;
    border-block-start: 1px solid transparent;
    border-block-start-color: var(--cds-border-subtle-01,#c6c6c6);
    color: var(--cds-text-secondary,#525252);
    font-weight: 400;
    line-height: 1rem;
    text-decoration: none;
    text-overflow: ellipsis;
    transition: border-color 70ms cubic-bezier(.2,0,.38,.9),color 70ms cubic-bezier(.2,0,.38,.9);
    white-space: nowrap;
}

.capability-dropdown-wrapper .list-box__menu-item__option{
    display: block;

    padding: .6875rem 0;
    margin: 0 1rem;
    -webkit-padding-end: 1.5rem;
    padding-inline-end: 1.5rem;

    text-decoration: none;
    color: inherit;
    text-wrap: wrap;
    height: max-content;
}

.capability-dropdown-wrapper .list-box__menu-item:hover {
    background-color: #e8e8e8;
}

.capability-dropdown-wrapper .list-box__menu-item--highlighted {
    outline: 2px solid var(--cds-focus,#0f62fe);
    outline-offset: -2px;
    color: var(--cds-text-primary,#161616);
}

.capability-dropdown-wrapper .dropdown-helptext{
    color: #6F6F6F;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    column-gap: 4px;
    margin-top: 8px;
}
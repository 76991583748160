.checkbox-group-title{
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    column-gap: 12px;
    align-items: center;
    padding: 8px 4px;
    cursor: pointer;
}

.checkbox-group-title:hover{
    background: var(--background-hover, rgb(141, 141, 141, 12%));
}

.checkbox-group-title .checkbox-label-text{
    font-size: 14px;
}

button.checkbox-group-toggle-btn {
    height: 16px;
    width: 16px;
    border: none;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3px;
    cursor: pointer;
    transition: all 110ms cubic-bezier(0,0,.38,.9);
}

.expanded button.checkbox-group-toggle-btn{
    -o-transform: rotateZ(180deg);
    -ms-transform: rotateZ(180deg);
    -moz-transform: rotateZ(180deg);
    -webkit-transform: rotateZ(180deg);
    transform: rotateZ(180deg);
}

ul.checkbox-children {
    list-style: none;
    margin-left: 13px;
    border-left: solid var(--border-strong-01, #8d8d8d) 1px;
    padding-left: 16px;

    transition: all 110ms cubic-bezier(0,0,.38,.9);
    overflow: hidden;
    max-block-size: 0px;
    opacity: 0;

    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
}

ul.checkbox-children label{
    font-size: 13px;
}

.expanded ul.checkbox-children{
    padding: 4px 4px 4px 16px;
    overflow: visible;
    max-block-size: fit-content;
    opacity: 1;
}

/* ul.checkbox-children li {
    margin-bottom: 8px;
} */
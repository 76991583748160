.main-search-content {
    min-height: max(300px, calc(100vh - 64px));
    /* display: grid;
    grid-template-columns: 300px auto; */
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    padding: 16px 0px;
    max-width: min(95vw, 1800px);
    width: 92vw;
    margin: auto;
}

@media screen and (max-width: 1800px) {
    .main-search-content {
        margin-left: 2.8vw;
        margin-right: 3.2vw;
    }
}

.filter-section {
    flex: 1 1;
    max-width: 360px;
    padding: 20px 32px;
    padding-right: 0px;
    height: max(300px, calc(100vh - 76px));

    overflow-x: hidden;
    overflow-y: auto;

    display: -webkit-flex;
    display: flex;
    flex-direction: column;

    position: sticky;
    top: 80px;
}

.filter-section-header{
    font-size: 14px;
    line-height: 20px;
    color: var(--text-secondary,#525252);
    padding-bottom: 16px;
    border-bottom: solid var(--border-subtle-01, #c6c6c6) 1px;

    display: flex;
    align-items: center;
    justify-content: space-between;
}

.filter-section-header h3{
    font-size: 14px;
}

.toggle-filter-btn{
    display: none;
}

/* fuzzy search styling */
.checkbox-input-wrapper {
    padding-bottom: 10px;
}

.checkbox-input-container {
    padding: 12px;
    background-color: var(--layer-02, #ffffff);
    border-bottom: solid var(--border-strong-01, #8d8d8d) 1px;

    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    column-gap: 12px;
}

.checkbox-input-container input {
    outline: none;
    border: none;
    flex: 1;
}

.checkbox-input-container input::placeholder{
    color: var(--text-placeholder, #a8a8a8);
    /* font-style: italic; */
}

.checkbox-input-container input:focus,
.checkbox-input-container input:focus-visible,
.checkbox-input-container input:focus-within,
.checkbox-input-container input:active{
    outline: transparent;
}

.checkbox-searchbox-result {
    color: var(--text-helper,#6f6f6f);
    font-size: 12px;
    padding-bottom: 20px;
    display: -webkit-flex;
    display: flex;
    flex-wrap: wrap;
    row-gap: 4px;
}

.filter-section .tab_list {
    margin-top: 16px;
    margin-bottom: 16px;
    border-bottom: solid var(--border-subtle-00,#e0e0e0) 1px;
    scale: 0.85;
    transform-origin: left;
}

.filter-section .tabs__nav-item {
    background-color: var(--background, #ffffff);
}

.filter-section .tabs__nav-item.tabs__nav-item--selected {
    background-color: var(--tag-background-blue, #d0e2ff);
    color: var(--tag-color-blue, #0043ce);
}

.checkbox-list {
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    flex: 1;

    overflow-y: auto;

    scrollbar-width: thin;
    scrollbar-color: #8d8d8d #f4f4f4;

    padding-right: 12px;
}

#gcse-searchresults .gsc-result-info {
    color: var(--text-secondary, #525252);
    font-size: 12px;
    margin: 0;
    padding: 0px 4px 4px 4px;
    text-align: left;
}

#gcse-searchresults.no-results{
    height: 250px;
    height: min(100%, 250px);
    height: min(250px, calc(100% - 100px));
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.search-results-section{
    flex: 1 1;
    padding: 16px 44px;
}

.search-results-section h3 {
    font-size: 14px;
    line-height: 20px;
    color: var(--text-secondary,#525252);
    padding-bottom: 16px;
    border-bottom: solid var(--border-subtle-01, #c6c6c6) 1px;
    font-weight: 400;
    padding-top: 4px;
    margin-bottom: 8px;
}

.actions-container {
    height: 44px;

    display: -webkit-flex;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    column-gap: 8px;
    margin-top: 12px;
    margin-right: 4px;
}

.actions-container button{
    font-size: 14px;
    line-height: 18px;
    height: 32px;
    width: 80px;

    cursor: pointer;
}

/* Overwriting google search styling */

.gsc-control-cse{
    background: transparent !important;
    border: none !important;
}

/* Hiding gsc header */
/* div#___gcse_1 {
    display: none !important;
    max-block-size: 0px !important;
} */

.gsc-control-cse.gsc-control-cse-en {
    padding: 0px;
    border-bottom: none;
}

/* Hiding Ads */
.gsc-adBlock {
    display: none !important;
    max-block-size: 0px !important;
}

/* Hiding pagination */
body:not(.mobile) .gsc-tabData.gsc-tabdActive {
    display: none !important;
    max-block-size: 0px !important;
}

.gsc-webResult.gsc-result {
    display: none !important;
}

.gcsc-find-more-on-google-root{
    display: none !important;
}

/* Hiding result count */
.gsc-result-info-container {
    display: none !important;
}

.gsc-above-wrapper-area{
    border: none !important;
}

.pagination-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 0px 0px;
    border-top: solid 1px var(--border-subtle-01, #c6c6c6);
    margin-top: 32px;
    column-gap: 12px;
    color: var(--text-secondary,#525252);
    font-size: 14px;
}

.change-page-btn{
    height: 32px;
    width: 32px;
    cursor: pointer;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    border-radius: 0px;
    -o-border-radius: 0px;
    -ms-border-radius: 0px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
}

.change-page-btn.prev-page{
    transform: rotateZ(-90deg);
    -webkit-transform: rotateZ(-90deg);
    -moz-transform: rotateZ(-90deg);
    -ms-transform: rotateZ(-90deg);
    -o-transform: rotateZ(-90deg);
}

.change-page-btn.next-page{
    transform: rotateZ(90deg);
    -webkit-transform: rotateZ(90deg);
    -moz-transform: rotateZ(90deg);
    -ms-transform: rotateZ(90deg);
    -o-transform: rotateZ(90deg);
}

.change-page-btn svg {
    transform: scale(1.2);
    -webkit-transform: scale(1.2);
    -moz-transform: scale(1.2);
    -ms-transform: scale(1.2);
    -o-transform: scale(1.2);
}

.change-page-btn:hover{
    background-color: var(--background-hover, rgba(141, 141, 141, 12%));
}

.change-page-btn:focus,
.change-page-btn:focus-visible,
.change-page-btn:focus-within{
    outline: solid var(--focus, #0f62fe) 2px;
}

.open-filter-btn{
    display: none;
}

@media screen and (max-width:1000px){
    .filter-section {
        padding-left: 0px !important;
        max-width: 270px !important;

    }
    .checkbox-input-container{
        max-width: 260px !important;
    }
    .filter-section .tab_list{
        scale: 0.9 !important;
    }
}

@media screen and (max-width:655px) {
    section.filter-section {
        position: fixed;
        z-index: 1000;
        background-color: #f4f4f4;
        padding: 24px;
        box-shadow: 0px 0px 10px 1px lightgrey;
        top: 0px;
        left: 0px;
        height: 100%;
        transition: left 250ms linear;
        -webkit-transition: left 250ms linear;
        -moz-transition: left 250ms linear;
        -ms-transition: left 250ms linear;
        -o-transition: left 250ms linear;
        padding-left: 24px !important;
    }

    .toggle-filter-btn{
        display: flex;
        align-items: center;
        justify-content: center;

        border: none;
        background-color: transparent;
        
        height: 24px;
        width: 24px;

        cursor: pointer;
    }

    .toggle-filter-btn:hover{
        background-color: var(--background-hover, rgb(141, 141, 141, 12%));
    }

    .toggle-filter-btn:focus{
        outline: solid var(--border-interactive, #0f62fe) 2px;
        outline-offset: -2px;
    }

    .toggle-filter-btn svg{
        transform: scale(1.5);
    }

    .main-search-content:not(.filter-expand) section.filter-section{
        left: -400px;
    }

    .open-filter-btn{
        display: block;
        padding: 6px 12px;
        margin-bottom: 12px;
        cursor: pointer;
    }

    .search-results-section{
        padding:2px 16px ;
    }
 
}

.no-search-results-container {
    text-align: center;
}

/* Hiding the order by dropdown */
td.gsc-orderby-container {
    display: none !important;
}

/* body:not(.mobile) div#___gcse_1 {
    display: none !important;
} */

div#___gcse_1{
    background-color: transparent !important;
}

.gsc-results{
    background-color: transparent !important;
}


/* Mobile styling */
.mobile div#gcse-searchresults {
    display: grid;
}

.mobile #___gcse_1 {
    grid-row: 100;
}

.mobile .pagination-container {
    display: none;
}

.mobile .gsc-results .gsc-cursor-box{
    border: none !important
}
#landing-banner.large {
    width: 100%;
    min-height: 320px;
    height: 320px;
    max-height: 320px;
    position: relative;
    overflow: hidden;
}

#landing-banner.large #banner-image-container {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
}

#landing-banner.large #banner-image-container svg,
#landing-banner.large #banner-image-container svg path {
    height: 100%;
    width: 100%;
}

#landing-banner.large #landing-banner-header {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 40px 70px;
    
}

#landing-banner.large #landing-banner-title {
    font-size: 32px;
    color: white;
    font-weight: 400;
    margin: 20px 0px 16px;
}

#landing-banner.large #landing-banner-searchbox {
    margin-top: 8px;
    min-width: 180px;
    width: 70vw;
    max-width: 610px;
    min-width: 229px;
    height: 46px;
    /* background-color: var(--lavender-light, #D8CBF0); */
    background-color: white;

    border-bottom: solid 1px var(--core-dark, #011F3D);
    padding: 0px 4px;

    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

#landing-banner.large #landing-banner-search-icon {
    height: 32px;
    width: 32px;

    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

#landing-banner.large #landing-banner-gsc-search {
    -webkit-box-flex: 1;
    width: calc(100% - 60px);
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

/* #landing-banner .gsc-search-button.gsc-search-button-v2 {
    display: none;
} */

#landing-banner.large #landing-banner .gsc-search-button-v2 svg{
    transform: scale(1.4);
}

/* Searchbox styling */
#landing-banner.large .gsc-input {
    padding: 0px;
}

#landing-banner.large .gsc-input-box#gsc-iw-id1 {
    background-color: transparent;
    border: none;
}

#landing-banner.large td.gsib_a#gs_tti50 input {
    background-color: transparent;
}

#landing-banner.large table.gsc-search-box td {
    padding-top: 2px !important;
}

#landing-banner.large #landing-banner-logo {
    margin: 26px 8px 8px 26px;
    width: 64px;
}

@media screen and (max-width: 771px){
    #landing-banner.large #landing-banner-header {
        top: 60%;
        padding: 20px 30px;
    }
}

#landing-banner.large #logo-container{
    display: none;
}

/* --------------- Small banner styling ---------------- */
#landing-banner.small #banner-image-container {
    display: none;
}

#landing-banner.small #landing-banner-title{
    display: none;
}

#landing-banner.small {
    height: 64px;
    max-height: 64px;
    overflow: hidden;
    padding: 16px 24px;
    background-color: white;
    box-shadow: 0px 2px 12px 2px rgba(1, 31, 61, 0.12);

    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    row-gap: 8px;
    justify-content: space-between;
    align-items: center;

    position: fixed;
    top: 0px;
    width: 100vw;
    z-index: 998;
}

#landing-banner.small  #landing-banner-header {
    width: max-content;
}

#landing-banner.small #gcse-searchbox{
    border: solid #011F3D 1px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 32px;

    min-width: 160px;
    width: 40vw;
    max-width: 360px;
}

#landing-banner.small #___gcse_0 {
    width: 100%;
}

#logo-container img{
    height: 18px;
    width:auto;
}

@media screen and (max-width: 470px) {
    #landing-banner.small{
        height: max-content;
        max-height: 102px;
    }

    #landing-banner.small #landing-banner-header {
        width: 100%;
    }

    #landing-banner.small #gcse-searchbox{
        width: 100%;
        max-width: revert;
    }
}

.footer {
    background-color: var(--layer-01,#F4F4F4);
    /* color: grey; */
    color: var(--text-secondary, #525252);
    /* padding: 0 0 10px 0;4 */
    padding: 30px 8px 20px;
    text-align: center;
    font-size: 12px;
    position: relative;
    width: 100%;
  }
  
  .footer p {
    margin: 0;
  }
  
  
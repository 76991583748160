.main-content {
    background-color: var(--layer-01,#F4F4F4);
    width: 100%;
    height: max-content;
    min-height: calc(100vh - 320px);
    padding-top: 10px;
    overflow: visible;

}

.capabilities-section {
    padding-bottom: 40px;
    display: -ms-grid;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 408px));
    column-gap: 24px;
    row-gap: 24px;

    justify-content: center;
}

#capabilities-section-title {
    font-size: 32px;
    color: var(--core-dark);
    font-weight: normal;
    grid-column: 1/-1;
    margin-top: 24px;
    margin-bottom: 0;
    /* color:rebeccapurple ; */
}

/* products section styling */
#products-section {
    padding: 40px 20px;
    padding-bottom: 0px;
    height: max-content;
    min-height: 100vh;

    display: grid;
    grid-template-columns: 324px calc(100% - 324px);

    position: relative;
    margin: auto;
    width: 92vw;
    max-width: min(95vw, 1800px);
}

@media screen and (max-width: 901px){
    #products-section {
        grid-template-columns: 260px calc(100% - 260px);
    }
}

.capabilities-toc {
    background-color: var(--layer-accent-01,#e0e0e0);
    display: flex;
    flex-direction: column;
    
    height: min-content;
    max-height: 100vh;

    height: 100%;
    overflow-y: auto;
    position: sticky;
    top: 65px;
    left: 0px;
}

.capabilities-toc::-webkit-scrollbar {
    width: 6px;
    height: 4px;
    scroll-margin-top: 20px;
}

 .capabilities-toc::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
 }

 .capabilities-toc::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    -o-border-radius: 10px;
    -ms-border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
}

.capabilities-toc-item {
    color: var(--text-secondary,#525252);
    padding: 20px 20px 20px 48px;
    border-bottom: solid var(--border-subtle-01,#c6c6c6) 1px;
    font-size: 15px;
}

.capabilities-toc-item.active {
    border-left: solid var(--accent-color-primary, #8e3cf7) 4px;
    background-color: white;
    font-weight:700;
}

.capabilities-toc-item:hover {
    background-color: var(--layer-selected-hover-01,#cacaca);
    cursor: pointer;
}

.capabilities-toc-item.active:hover {
    background-color: var(--layer-01,#f4f4f4);
}

.capabilities-toc-item:focus,
.capabilities-toc-item:active {
    outline: solid var(--accent-color-primary, #8e3cf7) 2px;
    outline-offset: -2px;
}

.flex-wrapper{
    width: 100%;
    max-width: 100%;
    padding-left: 20px;

    display: grid;
    grid-template-columns: repeat(auto-fill, 288px);
    column-gap: 12px;
    /* row-gap: 12px; */
    padding: 4px 0px;
    justify-content: flex-start;

    /* max-width: min(910px, 100%); */
}

.products-container {
    background-color: white;
    padding: min(20vw, 40px);
    padding-top: 18px;
    position: relative;

    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    align-items: left;
}


/* .products-container .tab_list{
    position: sticky;
    top: 0px;
    z-index: 2;
} */

.products-container-header {
    background-color: white;
    padding-top: 22px;
    padding-bottom: 20px;

    position: sticky;
    top: 64px;
    z-index: 2;

    grid-column: 1/-1;

    display: grid;
    grid-template-columns: repeat(auto-fill, 288px);
    column-gap: 12px;
    padding: 4px 0px;
    margin-left: -1px;

    /* max-width: 1200px; */
    justify-content: flex-start;
    padding-bottom: 26px;
    max-width: 101%;
    box-sizing: border-box;
}

.products-container-header > *{
    grid-column: 1/-1;
}

.products-container-header h2{
    font-size: 20px;
    font-weight: 400;
    padding-top: 32px;
    margin-bottom: 8px;
    font-weight: 400;
}

.products-container-header p {
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
    padding: 12px 16px 12px 0px;
}

.capability-dropdown-wrapper{
    max-width: 313px;
    display: none;
}

.inpage-searchbox-label {
    display: block;
    padding: 8px 4px 6px 0px;
    font-size: 12px;
    line-height: 12px;
    margin-bottom: 2px;
    color: var(--text-secondary, #525252);
}

.inpage-searchbox {
    background-color: #f4f4f4;
    background-color: var(--field-01,#f4f4f4);
    border-bottom: 1px solid #8d8d8d;
    border-bottom: 1px solid var(--border-strong-01,#8d8d8d);
    column-gap: 12px;
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
    max-width: 288px;
    padding: 8px;
}

@media screen and (min-width: 1485px) { 
    .flex-wrapper{
        column-gap: 20px !important;
    }

    .products-list{
        column-gap: 20px !important;
        row-gap: 20px !important;
    }

    .products-container-header{
        column-gap: 20px !important;
    }

    .products-container{
        padding-left: 74px;
    }
}

@media screen and (max-width: 1405px){

    .flex-wrapper{
        column-gap: 20px !important;
        /* row-gap: 20px !important; */
    }

    .products-list{
        column-gap: 20px !important;
        row-gap: 20px !important;
    }

    .products-container-header{
        column-gap: 20px !important;
    }
}

@media screen and (max-width: 771px){

    .capabilities-toc{
        display: none;
    }

    .flex-wrapper {
        /* display: -webkit-flex;
        display: flex; */
        flex-direction: column;
        align-items: center;
        /* width: 288px; */
        justify-content: center;
    }

    #products-section{
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 4px;
    }

    .products-container{
        align-items: center;
    }

    .products-container-header,
    .products-list{
        /* display: -webkit-flex !important; */
        /* display: flex !important; */
        flex-direction: column;
        width: 100%;

    }

    .products-container-header{
        padding-bottom: 2px;
        top:62px;
        margin-left: -0px;
        /* width: calc(100% + 8px); */
    }

    /* .products-list{
        padding: 4px !important;
    } */

    .capability-dropdown-wrapper{
        display: block;
        margin-top: 0;
    }

    .inpage-searchbox{
        margin-top: 10px;
        height: 36px;
    }

    .products-container-header h2{
        padding-top: 14px;
        margin-bottom: 12px;
    }
    .tabs__nav-item{
        height: 45px;
        min-width:95px
    }
    
    .tabs__nav-item.tabs__nav-item--selected{
        height: 45px;
    }

    .tabs__nav-item{
        font-size: 13px;
    }


}

@media screen and (max-width: 470.5px){
    .products-container-header{
        padding-bottom: 2px;
        top:80px; 
    }
    #main-nav{
        height: max-content;
        max-height: 92px;
    }

    #main-nav #search-container {
        width: 100%;
        max-width: revert;
    }

}

@media screen and (max-width:402.5px) {
    .tab_list{
        display: flex;
    }
    
    

    span.tabs__nav-item-label {
        text-wrap: nowrap;
        font-size: 12px;
    }
}

.inpage-searchbox input#product-searchbox{
    border: none;
    background-color: transparent;
    flex: 1;
    outline: none;
    width: 100%;
}

.inpage-searchbox input::placeholder{
    color: var(--text-placeholder,#a8a8a8);
    font-weight: 300;
    /* font-style:italic; */
}

.inpage-searchbox svg{
    fill: var(--icon-secondary,#525252);
}
.clear-searchbox-button {
    background: none;
    border: none;
    cursor: pointer;
    margin-right: 4px;
}

.product-searchbox-result {
    color: #6f6f6f;
    color: var(--text-helper,#6f6f6f);
    font-size: 12px;
}

/* .search-count{
    font-weight: bold;
} */
.alt-result{
    color: var(--link-primary,#0f62fe);
    text-decoration: underline;
    cursor: pointer;
    /* margin-left: 8px;
    padding-left: 8px; */
    /* border-left: solid var(--text-helper,#6f6f6f) 1.5px; */
}
.slash{
    padding:0 3px ;
   
}

.products-list {
    grid-column: 1 / -1;
    display: grid;
    grid-template-columns: repeat(auto-fill, 288px);
    grid-template-rows: 144px;
    column-gap: 12px;
    row-gap: 12px;
    padding: 4px 0px;
    justify-content: flex-start;
}

#no-products-found-text{
    font-style: italic;
    color: var(--text-secondary, #525252);
}

/* IBM Redirection Banner Styling */

.ibm-redirection-banner{
    background-color: #EDF5FF;
    border: solid #B5CBF6 1px;
    box-shadow: 0px 2px 12px 2px rgba(1, 31, 61, 0.12);
    clip-path: inset(0px 0px -30px 0px);
    padding: 12px;
}
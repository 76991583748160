#feedback-tab,
#feedback-form {
   display: none;
}

@media only screen and (max-width: 501px) {
  #feedback-tab,
  #feedback-form {
    display: none !important;
  }

  #mob_feedback-tab {
    display: block;
  }
}

@media only screen and (min-width: 501px) {
    
   #feedback-tab,
   #feedback-form {
      display: block;
   }

   #mob_feedback-tab,
   #mob_feedbackform {
      display: none !important;
   }
}

.feedback {
    display: inline-block;
    width: 45px;
    height: 45px;
    background-position: 50% 50%;
    background-size: 48%;
    background-repeat: no-repeat;
    border-radius: 50%;
    background-color: #0043CE;
    color: white;
    box-shadow: 0 6px 12px rgb(0 0 0 / 18%);
    cursor: pointer;
}
  
#feedback-form {
  display: block; 
}

.feedback-icon {
  background-image: url('./feedback-2.svg');
  position: fixed;
  bottom: 24px;
  right: 14px;
  z-index: 996;
}

.feedback-icon:hover {
  background-image: url('./feedback-hover.svg');
  background-color: white;
  border: 2px solid #0043CE;
}

.feedback-icon-active {
  background-image: url('./feedback-hover.svg');
  background-color: var(--tag-background-blue, #d0e2ff);
  border: solid #0043CE 2px;
}

.feedback-icon-active.down-arrow {
  background-color: #0043CE;
  background-image: url('./dlt-icon-chevron-down.svg');
  background-size: 100%;
}

form {
  font-family: 'Roboto', sans-serif;
  font-size: 13px;
  user-select: none;
}

#feedback-form, #mob_feedbackform {
  width: 332px;
  position: fixed;
  z-index: 999;
  bottom: 81px;
  right: 25px;
  opacity: 1;
  background: white;
  box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
  padding: 24px;
  transition: all 250ms linear;
  -webkit-transition: all 250ms linear;
  -moz-transition: all 250ms linear;
  -ms-transition: all 250ms linear;
  -o-transition: all 250ms linear;
}

#mob_feedbackform{
  width: 288px;
  bottom: 20px;
  right: 10px;
}

.formheader {
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 12px;
}

.feedback-radio-container {
  display: flex;
  justify-content: center;
  column-gap: 24px;
  margin-bottom: 14px;
}

.response-option-label {
  display: inline-flex;
  flex-direction: column;
  width: 40px;
  margin: 0;
  align-items: center;
  row-gap: 8px;
  cursor: pointer;
  padding: 5px;
  outline: transparent;
}
  
.response-option-label:hover,
.response-option-label:focus {
  outline: solid var(--button-tertiary-hover, #0353e9) 2px;
}

.response-option-label:active {
  outline: solid var(--button-tertiary-hover, #0353e9) 2px;
  background-color: #d0e2ff;
}

.response-option-label img.response-default {
  background-color: white;
  border-radius: 100%;
}
  
.response-option-label img.response-active {
  display: none;
}

.response-option-item input {
  height: 0;
  margin: 0;
  opacity: 0;
  padding: 0;
  position: absolute;
  width: 0;
  outline: transparent;
}
  
.response-option-item input:checked + label > img.response-default {
  display: none;
}

.response-option-item input:checked + label > img.response-active {
  display: block;
}

/* Feedback Radio Options Styling : This site or specific content inputs */
  
.mt-2.mb-2 {
  margin-bottom: 18px;
  width: 284px;
}

.feedbackoptions.container{
  position: relative;
}

.feedbackoptions.container.invalid::before{
  content: attr(data-required-message);
  color: var(--text-error, #da1e28);
  margin-top: 4px;

  font-size: 10px;
  display: block;
  height: 16px;

  position: absolute;
  left: -1px;
  top: 38px;
}

/* .feedbackoptions.container.required.invalid::before,
.feedbackoptions.container.invalid::before {
  content: attr(data-invalid-message);
  color: var(--text-error, #da1e28);
  padding-left: 18px;
  margin-top: 4px;
  font-size: 12px;

  display: block;
  height: 16px;

  background-image: var(--icon-invalid);
  background-size: 14px;
  background-repeat: no-repeat;

  position: absolute;
  left: -1px;
  top: 38px;
} */

.feedbackoptions.container.mt-3>span {
  font-size: 12px;
  line-height: 16px;
  color: var(--text-secondary, #525252);
  display: block;
  margin-bottom: 4px;
  grid-row: 1;
}

#feedbackOption-container[invalid]{
  border: solid black 2px;
}
  
.form-check input[type="radio"],
.form-check input#article,
.form-check input#specific {
   height: 0 !important;
   width: 0 !important;
   margin: 0;
   opacity: 0;
   padding: 0;
   line-height: 0px;
   position: absolute;
}

.radio-input-wrapper{
  display: inline-block;
}

.form-check-label {
  font-size: 12px;
  line-height: 18px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  padding-left: 16px;
  color: var(--text-primary, #161616);
  margin-right: 14px;
}
  
.form-check-label::before {
  content: '';
  height: 12px;
  width: 12px;
  display: inline-block;
  border: solid var(--icon-primary, #161616) 1px;
  background-color: white;
  border-radius: 50%;
  margin-right: 8px;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 2px;
}
  
.form-check input[type="radio"]:checked + label::after {
  content: '';
  height: 5.7px;
  width: 5.7px;
  background-color: var(--icon-primary, #161616);
  border-radius: 50%;
  position: absolute;
  left: 3px;
  top: 5.2px;
}

div#selected-text-container{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

div#selected-text-container textarea{
  color: var(--text-helper, #6f6f6f);
}

div#selected-text-container a{
  color: var(--link-primary, #0f62fe);
  display: block;
}

.comments-group{
  margin-top: 12px;
}

div#selected-text-container a:focus{
  border-color: transparent;
  outline: solid var(--link-primary, #0f62fe) 1px;
}
  
  #feedback-form.specific #selected-text-container {
    display: block;
    margin-bottom: 16px;
  }
  
  #copiedText {
    display: none;
  }
  
  #guidetext {
    height: max-content;
    max-height: 81px;
    overflow: hidden;
    background-color: white;
    font-size: 12px;
    line-height: 18px;
    color: rgba(54, 89, 125, 0.7);
    font-style: italic;
    border: none;
    width: 280px;
    padding: 8px 0px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    /* -webkit-line-clamp: 4; */
    white-space: pre-wrap;
    pointer-events: none;
  }
  
  #clearlink {
    border: none;
    background-color: transparent;
    display: inline-block;
    padding: 2px;
    color: var(--button-tertiary, #0f62fe);
    cursor: pointer;
  }
  
  #clearlink:hover {
    color: #0043CE;
    text-decoration: underline;
  }
  
  #clearlink:focus {
    outline: solid #0043CE 1px;
    text-decoration: underline;
  }
  
  #clearlink:active {
    color: #0043CE;
  }
  
.form-group {
  display: grid;
  margin-bottom: 24px;
  width: max-content;

  position: relative;
}
  
.form-group label {
  font-size: 12px;
  line-height: 16px;
  color: var(--text-secondary, #525252);
  margin-bottom: 4px;
  grid-row: 1;
}
  
textarea#comments {
  width: 284px;
  height: 100px;
  resize: none;
  font-size: 12px;
  line-height: 16px;
  overflow: auto;
  border: none;
  outline: transparent;
  background-color: var(--field-01, #f4f4f4);
  border-bottom: solid var(--border-strong-01, #8d8d8d) 1px;
  margin: 0;
  margin-bottom: 4px;
  padding: 12px 8px;
}

textarea#comments::placeholder{
  font-style: italic;
}

textarea#comments ~ label {
  display: -webkit-flex;
  display: flex;
  width: 284px;
  align-items: center;
  justify-content: space-between;
}
  
textarea#comments:disabled{
  cursor:not-allowed;
  color: var(--text-disabled, rgba(22, 22, 22, 25%));
  border-bottom-color: transparent;
}

textarea#comments:disabled::placeholder{
  color: var(--text-disabled, rgba(22, 22, 22, 25%));
}

textarea#comments:disabled ~ label{
  color: var(--text-disabled, rgba(22, 22, 22, 25%));
}
 
textarea#comments:focus {
  outline: solid var(--focus, #0f62fe) 2px;
}

/* textarea#comments:invalid{
  outline: solid var(--support-error, #da1e28) 2px;
  padding-right: 32px;
} */

/* textarea#comments:invalid ~ label::before{
  content: ' ';

  display: block;
  width: 16px;
  height: 16px;
  background-image: var(--icon-invalid);

  position: absolute;
  right: 12px;
  top: 32px;
} */

textarea#comments:invalid ~ label::after{
  content: attr(data-invalid-message);
  color: var(--text-error, #da1e28);
  font-size: 10px;

  position: absolute;
  left: 0px;
  top: 130px;
}
  
#charNum {
  margin: 0;
  padding-top: 4px;
  font-size: 12px;
  line-height: 16px;
  width: max-content;
  align-items: center;
  margin-right: 4px;
}
 
  #cancel, #mob_cancel, #submit, #mob_submit {
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    width: 72px;
    height: 34px;
    outline: none;

    display: inline-flex;
    justify-content: center;
    align-items: center;

    transition: all 150ms linear;
    -webkit-transition: all 150ms linear;
    -moz-transition: all 150ms linear;
    -ms-transition: all 150ms linear;
    -o-transition: all 150ms linear;

    border: solid transparent 1px;
    cursor: pointer;
}

/* Primary Button Styling */
#submit, #mob_submit {
  background-color: var(--button-primary, #0f62fe);
  color: var(--text-on-color, #ffffff);
}

#submit:enabled:hover, #mob_submit:enabled:hover {
  background-color: var(--button-primary-hover, #0353e9);
}

#submit:enabled:active, #mob_submit:enabled:active {
  background-color: var(--button-primary-active, #002d9c);
}

#submit:enabled:focus, #mob_submit:enabled:focus,
#submit:enabled:focus-visible, #mob_submit:enabled:focus-visible,
#submit:enabled:focus-within, #mob_submit:enabled:focus-within{
  border-color: var(--focus,#0f62fe);
  box-shadow: inset 0 0 0 1px var(--focus,#0f62fe),inset 0 0 0 2px var(--background,#ffffff);
}

#submit:disabled, #mob_submit:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Tertiary Button Styling */

#cancel{
  margin-right: 6px;
}

#cancel, #submit {
  float: right;
  margin-top: 2px;
}

#cancel, #mob_cancel {
  color: var(--button-tertiary, #0f62fe);
  border: solid var(--button-tertiary, #0f62fe) 1px;
  background-color: transparent;
}

#cancel:hover, #mob_cancel:hover {
  background-color: var(--button-tertiary-hover, #0353e9);
  color: var(--text-on-color, #ffffff);
}

#cancel:enabled:focus, #mob_submit:enabled:focus,
#cancel:enabled:focus-visible, #mob_submit:enabled:focus-visible,
#cancel:enabled:focus-within, #mob_submit:enabled:focus-within{
  background-color: var(--button-tertiary, #0f62fe);
  color: var(--text-inverse, #ffffff);
  border-color: var(--focus,#0f62fe);
  box-shadow: inset 0 0 0 1px var(--focus,#0f62fe),inset 0 0 0 2px var(--background,#ffffff);
}

#cancel:active, #mob_cancel:active {
  background-color: var(--button-tertiary-active, #002d9c);
  color: var(--text-on-color, #ffffff);
}

  
#privacyPolicy2 {
  text-decoration: none;

  display: inline-block;
  font-size: 12px;
  padding: 4px;
  line-height: 16px;

  color: var(--link-primary, #0f62fe);
  outline: transparent;
  cursor: pointer;
  position: relative;
}

#privacyPolicy2:hover{
  color: var(--link-primary-hover, #0043ce);
  text-decoration: underline;
}
 
#privacyPolicy2:active,
#privacyPolicy2:focus {
  outline: 1px solid var(--focus, #0f62fe);
  text-decoration: underline;
}
 
 #privacyPolicy2>.divClass {
    display: none;
    visibility: hidden;
    font-family: "Roboto", "Helvetica", Arial, sans-serif;
 }
 
#privacyPolicy2:hover>.divClass {
    display: inline-block;
    visibility: visible;
    background-color: white;
    position: absolute;
    width: 250px;
    height: auto;
    font-family: "Helvetica Neue", "Helvetica", Arial, sans-serif;
    left: -260px;
    top: -12px;
    padding: 4px;
    border: solid 1px #d0d0d0;
    box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.16);
 }
 
 
 #privacyPolicy2:hover>.divClass::after {
    content: '';
    display: block;
    position: absolute;
    width: 12px;
    height: 12px;
    right: -6px;
    top: 18px;
    background-color: white;
    border: solid 1px #d0d0d0;
    border-bottom: none;
    border-left: none;
    transform: rotateZ(45deg);
 }
 
 #privacyPolicy2:hover>.divClass>#tooltip_title {
    display: inline-block;
    font-size: 12px;
    font-weight: 600;
    height: auto;
    width: 100%;
    padding: 4px;
    font-family: "Helvetica Neue", "Helvetica", Arial, sans-serif;
    color: #011F3d;
    line-height: 16px;
 }
 
 #privacyPolicy2:hover>.divClass>#privacy_tooltip {
    display: inline-block;
    font-size: 12px;
    font-weight: 400;
    color: #011F3d;
    background-color: white;
    height: auto;
    padding: 0px 4px 4px;
    font-family: "Helvetica Neue", "Helvetica", Arial, sans-serif;
    line-height: 16px;
 }
 
 /* ================== Feedback Form button styling ================== */
 
 .form-btn-container {
    position: absolute;
    right: 0;
    display: inline-flex;
    column-gap: 8px;
 }
 
 form#feedback-form div.form-btn-container #cancel,
 form#feedback-form div.form-btn-container #submit {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    width: 64px;
    height: 24px;
    outline: transparent;
    transition: all 250ms ease-in;
    display: flex;
    display: -webkit-flex;
    justify-content: center;
    align-items: center;
 }
 
 form#feedback-form div.form-btn-container #cancel {
    color: #0043CE;
    border: solid #0043CE 1px;
    background-color: white;
 }
 
 form#feedback-form div.form-btn-container #cancel:hover,
 form#feedback-form div.form-btn-container #cancel:focus {
    border-width: 2px;
 }
 
 form#feedback-form div.form-btn-container #cancel:active {
    padding-left: 8px;
    padding-right: 8px;
    background-color: #c5edea;
    color: #0043CE;
 }
 
 form#feedback-form div.form-btn-container #submit {
    background-color: #0043CE;
    color: white;
    border: solid #0043CE 1px;
 }
 
 form#feedback-form div.form-btn-container #submit:enabled:hover {
    background-color: white;
    color: #0043CE;
    border-width: 2px;
 }
 
 form#feedback-form div.form-btn-container #submit:enabled:active {
    color: white;
    background-color: rgb(28, 85, 105);
    border-color: rgb(28, 85, 105);
 }
 
 form#feedback-form div.form-btn-container #submit:disabled {
    opacity: 0.5;
    cursor: not-allowed;
 }
 
 .feedbackoptions.container.mt-3>#submit {
    margin-right: 10px;
 }
 
 .feedbackoptions.container.mt-3>#cancel {
    margin-right: 8px;
 }
 
 #emptyFormError {
    color: red;
    text-align: center;
 }

::-webkit-input-placeholder,
::-moz-placeholder,
:-ms-input-placeholder,
:-moz-placeholder {
  color: var(--text-placeholder, #a8a8a8);
}
  
div.email-group {
    display: -webkit-flex;
    display: flex;
    flex-direction: column-reverse;
    width: max-content;
    margin-bottom: 16px;
    position: relative;
 }
 
 div.email-group label {
    font-size: 12px;
    line-height: 16px;
    color: var(--text-secondary, #525252);
    margin-bottom: 8px;
    grid-row: 1;
 }
 
#userEmail {
  border: none;
  outline: transparent;

  height: 40px;
  width: 284px;
  padding: 8px 16px;
  background-color: var(--field-01, #f4f4f4);
  border-bottom: solid var(--border-strong-01, #8d8d8d) 1px;
  margin: 0;
  margin-bottom: 12px;
  padding: 8px;
}

#userEmail::placeholder{
  font-style: italic;
}

#userEmail.form-control:disabled{
  cursor:not-allowed;
  color: var(--text-disabled, rgba(22, 22, 22, 25%));
  border-bottom-color: transparent;
}

#userEmail.form-control:disabled::placeholder{
  color: var(--text-disabled, rgba(22, 22, 22, 25%));
}

#userEmail.form-control:disabled ~ label{
  color: var(--text-disabled, rgba(22, 22, 22, 25%));
}
 
#userEmail.form-control:focus {
  outline: solid var(--focus, #0f62fe) 2px;
}
 
#userEmail.form-control:invalid{
  outline: solid var(--support-error, #da1e28) 2px;
  padding-right: 48px;
}

#userEmail.form-control:invalid ~ label::before{
  content: ' ';

  display: block;
  width: 16px;
  height: 16px;
  background-image: var(--icon-invalid);

  position: absolute;
  right: 12px;
  top: 36px;
}

#userEmail.form-control:invalid ~ label::after{
  content: attr(data-invalid-message);
  color: var(--text-error, #da1e28);

  position: absolute;
  left: 0px;
  top: 70px;
}

/* Mobile feedback styling */
.d-flex.mt-2 {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.mob_Feedback_title.mob_container {
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.mob_feedbackform_title.mob_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

button.mob_Feedback_minimizeBtn {
  background: transparent;
  border: none;
  outline: transparent;
  cursor: pointer;

  height: 28px;
  width: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}

button.mob_Feedback_minimizeBtn:hover{
  outline: solid var(--button-tertiary-hover, #0353e9) 1px;
}

button.mob_Feedback_minimizeBtn svg {
  transform: rotateZ(90deg);
}

label.mob_comments_label{
  font-size: 12px;
  line-height: 16px;
  color: var(--text-secondary, #525252);
  margin-bottom: 4px;
  grid-row: 1;
}

textarea#mob_comments{
  width: 100%;
  height: 100px;
  resize: none;
  font-size: 12px;
  line-height: 16px;
  overflow: auto;
  border: none;
  outline: transparent;
  background-color: var(--field-01, #f4f4f4);
  border-bottom: solid var(--border-strong-01, #8d8d8d) 1px;
  margin: 0;
  margin-bottom: 4px;
  padding: 12px 8px;
}

textarea#mob_comments::placeholder{
  font-style: italic;
}

.mob_form-btn-container {
  padding-top: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  column-gap: 12px;
}

.top-btn {
  position: fixed;
  right: 15px;
  bottom: 24px;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: -0.01em;
  color: var(--accent-color-primary, #0043ce);
  background-color: white;
  border: solid var(--accent-color-primary, #0043ce) 1px;
  padding: 1px 9px;
  text-align: center;
  cursor: pointer;
  z-index: 997;
  outline: transparent;
  display: none;
}

.top-btn:hover,
.top-btn:focus,
.top-btn:active {
  padding: 0px 8px;
  border: solid var(--accent-color-primary, #0043ce) 2px;
  outline: transparent;
}

.top-btn:active {
  background-color: var(--accent-color-subtle, #d0e2ff);
}

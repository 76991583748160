li.breadcrumb-item {
    font-size: 13px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 10px;
}

li.breadcrumb-item a,
li.breadcrumb-item a:visited{
    color: var(--link-primary, #0f62fe);
}

li.breadcrumb-item a{
    text-decoration: none;
}

li.breadcrumb-item a:hover{
    color: var(--link-primary-hover, #0043ce);
    text-decoration: underline;
}


li.breadcrumb-item::after{
    content: ">";
    line-height: 1;
    margin-top: 2px;
    color: rgba(0,0,0,0);
    padding-right: 6.66px;
    background-image: var(--arrow-icon);
    background-repeat: no-repeat;
}
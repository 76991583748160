.tabs__nav-item {
    background-color:#F4F4F4;
    padding: 16px;
    min-width: 100px;
    font-size: 14px;
    border: none;
    color: var(--text-secondary,#525252);
    cursor: pointer;
    height: 51px;
    border-top-right-radius: 10px;
    font-size: 16px;
}

.tabs__nav-item.tabs__nav-link.disabled{
    cursor: not-allowed;
    color: var(--text-disabled, #8c8c8c);
    opacity: 0.8;
}

.tabs__nav-item.tabs__nav-link.disabled *{
    pointer-events: none;
}

.tabs__nav-item:not(.tabs__nav-item--selected):not(.disabled):hover{
    color: var(--text-primary, #161616);
    background-color: var(--layer-accent-hover-01, #cacaca);
}

.tabs__nav-item.tabs__nav-item--selected{
    background-color:var(--tag-background-blue, #d0e2ff);
    /* border-block-start: 2px solid var(--border-interactive, #0f62fe); */
    color:var(--tag-color-blue, #0043ce);
    font-weight: bold;
    height: 51px;
  
}

/* @media screen and (max-width: 771px){
    .tabs__nav-item{
        height: 45px;
        min-width:95px
    }
    
    .tabs__nav-item.tabs__nav-item--selected{
        height: 45px;
    }

    .tabs__nav-item{
        font-size: 13px;
    }
} */
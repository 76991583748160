.product-card {
    background-color: var(--background,#ffffff);
    border: solid 1px var(--border-subtle-01,#c6c6c6);
    padding: 16px;
    height: 144px;
    min-height: 144px;
    flex-direction: column;
    position: relative;
    max-width: 288px;

    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
}

.product-card:hover {
    background-color: var(--layer-01,#f4f4f4);
    cursor: pointer;
}

.product-card:active,
.product-card:focus {
    outline: transparent;
    /* outline-offset: -1px; */
}

.product-card:hover::before {
	width: 288px; /* Expands the width to 100% on hover */
}


.product-card::before {
	content: "";
	position: absolute;
	top: 0px;
	left: 50%;
	-webkit-transform: translate(-50%,-50%);
	transform: translate(-50%,-50%);
	width: 0;
	height: 2px;
	border-top: 2px;
	/* background: linear-gradient(99.14deg,#080051 6.93%,#d0e2ff 93.07%); */
    background: linear-gradient(99.14deg,#001141 6.93%,#4589ff 93.07%);
	-webkit-transition: width .2s;
	transition: width .2s;
	/* display: block; */
}
.product-card h4 {
    font-size: 15px;
    line-height: 22px;
    flex: 1;
    font-weight: 400;
}

.product-card-tags {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.product-card-tags-wrapper{
    display: flex;
    flex-wrap: wrap;
    row-gap: 5px;
    column-gap: 3px;
    align-items: center;
}

.product-card-tags-item {
    font-size: 10px;
    color: #343A3F;
    background-color: #DDE1E6;
    border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    -ms-border-radius: 12px;
    -o-border-radius: 12px;
    display: inline-block;
    width: max-content;
    height: max-content;
    padding: 3px 6px;
    display: inline;
    /* color: var(--tag-color-blue,#0043ce);
    background-color: var(--tag-background-blue,#d0e2ff); */
}

.product-card-tags-item.colored-tag{
    color: var(--tag-color-blue,#0043ce);
    background-color: var(--tag-background-blue,#d0e2ff);
}

.product-card-tags-item.update-tag {
    background-color: var(--accent-color-subtle, #d0e2ff);
    color: var(--accent-color-primary, #0043ce);
}

.product-card-link {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    cursor: pointer;
}

.product-card-link:focus {
    outline: solid var(--accent-color-primary, #0043ce) 2px;
    outline-offset: -2px;
}
li.quickfilter-item {
    list-style: none;
}

li.quickfilter-item button {
    background: transparent;
    border: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    font-size: 11px;
    border: solid var(--text-secondary,#525252) 1px;
    color: var(--text-secondary, #525252);

    padding: 6px 4px 6px 8px;
    border-radius: 30px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
}

li.quickfilter-item button:hover{
    background: var(--background-hover, rgb(141, 141, 141, 12%));
}
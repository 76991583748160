
.saas-migration-note {
    width: 100%;
    background-color: #FCF4D6 !important;
    /* padding: 12px !important; */
    border: 1px solid #F1C21B; 
    border-left-width: 3px;
    display: flex;
    align-items: center; 
    gap: 16px; 
    margin-top: 20px;
    margin-bottom: 10px;
    padding-right: 16px;
}

.saas-note-icon {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 16px;
}

.saas-note-icon svg {
    width: 17.5px;  
    height: 17.5px; 
}

.saas-note-text {
    flex-grow: 1;
    font-size: 14px;
    line-height: 18px;
    padding: 15px 0;
    letter-spacing: 0.16px;
}

.saas-note-text a,
.saas-note-text a:hover,
.saas-note-text a:visited{
    color: var(--link-primary, #0F62FE);
    text-decoration: underline;
}

#main-nav {
    height: 64px;
    max-height: 64px;
    overflow: hidden;
    padding: 16px 24px;
    background-color: white;
    box-shadow: 0px 2px 12px 2px rgba(1, 31, 61,0.12);

    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    row-gap: 8px;

    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0px;
    width: 100%;
    z-index: 998;
}

.sticky-wrapper #main-nav{
    position: static;
}

#logo-container img{
    height: 18px;
    width:auto;
}

#main-nav #search-container{
    min-width: 160px;
    width: 40vw;
    max-width: 360px;
}

/* @media screen and (max-width: 470px) {
    #main-nav{
        height: max-content;
        max-height: 92px;
    }

    #main-nav #search-container {
        width: 100%;
        max-width: revert;
    }
} */

#search-element{
    border: solid #011F3D 1px;

    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 32px;
}

#search-element input{
    flex: 1;
    border: none;
    padding: 4px;
}

.gsc-input-box{
    height: 26px !important;
    max-height: 26px !important;
    padding: 0px !important;
    margin-top: -2px !important;
}

#main-nav .gsc-input{
    height: 26px !important;
    max-height: 26px !important;
}

#main-nav table.gsc-search-box td{
    padding: 0px 4px !important;
}

#search-element input:hover,
#search-element input:active,
#search-element input:focus{
    outline: none;
}

/* Overwriting Google searchbar styling */
div#gcse-searchbox {
    flex: 1;
}

table.gsc-search-box {
    margin-bottom: 0px !important;
}

#main-nav button.gsc-search-button.gsc-search-button-v2 svg{
    transform: scale(1.2);
}

button.gsc-search-button.gsc-search-button-v2 {
    padding: 0px;
    width: 28px;
    height: 28px;
    cursor: pointer;
    margin-right: 2px;

    -o-border-radius: 0px;
    -ms-border-radius: 0px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    border-radius: 0px;
}

button.gsc-search-button.gsc-search-button-v2:hover{
    background-color: var(--background-hover, rgb(141, 141, 141, 12%));
}

button.gsc-search-button.gsc-search-button-v2:active{
    background-color: var(--background-active, rgba(141, 141, 141, 50%));
}

button.gsc-search-button.gsc-search-button-v2:focus,
button.gsc-search-button.gsc-search-button-v2:focus-within,
button.gsc-search-button.gsc-search-button-v2:focus-visible{
    outline: solid var(--border-interactive, #0f62fe) 2px;
    outline-offset: -2px;
}

table.gsc-search-box td.gsc-input {
    padding-right: 0px !important;
}

div.breadcrumb-wrapper{
    position: sticky;
    z-index: 997;
    top: 64px;
    width: 100vw;
    left: 0px;
    right: 0px;
    margin-top: 12px;

    background-color: var(--layer-01,#f4f4f4);
    clip-path: inset(0px -10px -10px -10px);
}

.sticky-wrapper div.breadcrumb-wrapper{
    margin-top: 0px;
    padding-top: 8px;
}

ul.breadcrumb-list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    column-gap: 2px;
    align-items: center;
    min-height: 40px;
    height: max-content;

    padding: 12px;
    padding-left: 20px;

    width: 92vw;
    max-width: min(95vw, 1800px);
    margin: auto;
    row-gap: 6px;

    background-color: var(--layer-01,#f4f4f4);
}

@media screen and (max-width: 771px) {    
    ul.breadcrumb-list {
        width: 98vw !important;
        max-width: min(98vw,1800px) !important;
    }
}

@media screen and (max-width: 470px) {
    div.breadcrumb-wrapper {
        top: 90px;
    }

    ul.breadcrumb-list{
        padding-left: 10px;
    }
}
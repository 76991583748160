#product-title-section {
    background-color: white;
    height: max-content;
    margin-bottom: 0px;
    padding: 24px 32px;
    height: 180px;

    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;

    background-size: cover;
}

#product-title{
    color: var(--text-on-color, #ffffff);
    font-size: 24px;
    font-weight: normal;
    
}

#product-title-section h3{
    font-weight: normal;
    color: var(--text-on-color, #ffffff);
    padding-top: 12px;
}

#product-title-section{
    background-size: cover;
    background-position: center;
}
.checkbox-wrapper{
    position: relative;
    width: max-content;
    max-width: 100%;
}

.checkbox{
    position: absolute;
    overflow: hidden;
    padding: 0;
    border: 0;
    block-size: 1px;
    clip: rect(0,0,0,0);
    inline-size: 1px;
    visibility: inherit;
    white-space: nowrap;
    inset-block-start: 1.25rem;
    inset-inline-start: .7rem;
}

.checkbox-label {
    color: var(--icon-secondary, #525252);
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    line-height: 1.28572;
    letter-spacing: 0.16px;
    position: relative;

    display: flex;
    align-items: center;

    cursor: pointer;
    min-block-size: 1.25rem;

    -webkit-padding-before: .125rem;
    padding-block-start: .125rem;

    -webkit-padding-start: 1.25rem;
    padding-inline-start: 1.25rem;

    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.checkbox-label::before {
    position: absolute;
    border: 1px solid var(--icon-secondary, #525252);
    border-radius: 2px;
    background-color: transparent;
    block-size: 1rem;
    content: "";
    inline-size: 1rem;
    inset-block-start: .125rem;
    inset-inline-start: 0;
    margin-block: .0625rem .125rem;
    margin-inline: .1875rem 0;
    margin-top: 0px;
}

.checkbox:checked+.checkbox-label::before, 
.checkbox:indeterminate+.checkbox-label::before, 
.checkbox-label[selected-checkbox-state=true]::before {
    border: none;
    border-width: 1px;
    background-color: var(--icon-secondary, #525252);
}

.checkbox-label::after {
    content: "";

    position: absolute;
    background: 0 0;
    block-size: .3125rem;
    -webkit-border-after: 1.5px solid var(--icon-inverse,#ffffff);
    border-block-end: 1.5px solid var(--icon-inverse,#ffffff);
    -webkit-border-start: 1.5px solid var(--icon-inverse,#ffffff);
    border-inline-start: 1.5px solid var(--icon-inverse,#ffffff);

    inline-size: .5625rem;
    inset-block-start: .40625rem;
    inset-inline-start: .4375rem;
    -webkit-margin-before: -.1875rem;
    margin-block-start: -.1875rem;
    -webkit-transform: scale(0) rotate(-45deg);
    transform: scale(0) rotate(-45deg);
    -webkit-transform-origin: bottom right;
    transform-origin: bottom right;
    margin-top: -4px;
}

/* .checkbox:checked+.checkbox-label::after, .checkbox-label[data-contained-checkbox-state=true]::after { */
.checkbox:checked+.checkbox-label::after, 
.checkbox-label[selected-checkbox-state=true]::after {
    -webkit-transform: scale(1) rotate(-45deg);
    transform: scale(1) rotate(-45deg);
}

.checkbox:indeterminate+.checkbox-label::after{
    -webkit-transform: scale(1) translate(-0.8px, 3.3px);
    transform: scale(1) translate(-0.8px, 3.3px);
    border-inline-start: none;
}

.checkbox-label-text {
    -webkit-padding-start: .625rem;
    padding-inline-start: .625rem;
}

.checkbox-children .checkbox-label::before {
    block-size: 14px;
    inline-size: 14px;
    inset-block-start: 0.125rem;
    margin-bottom: 0px;
    margin-top: 1.5px;
}

.checkbox-children .checkbox-label::after{
    inset-block-start: 6px;
    inset-inline-start: 6px;
    margin-top: -3px;
}
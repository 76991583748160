.product-landing-wrapper{
    width: 92vw;
    max-width: min(95vw, 1800px);
    margin: auto;
    margin-top: 4px;

    padding: 40px 20px;
    padding-bottom: 0px;
    padding-top: 0px;
}

@media screen and (max-width: 470px) {
    .product-landing-wrapper{
        padding-left: 0px;
        padding-right: 0px;
    }
}
#content-section {
    margin: 0px 75px;
    padding-top: 0px;
    height: max-content;
    min-height: min(58vh, 600px);
    background-color: var(--background, #ffffff);
    margin: auto;

    padding: 0px 32px;

    display: grid;
    grid-template-columns: auto 280px;
    grid-template-rows: max-content;
    column-gap: 32px;
}

#content-section.no-right-toc{
    grid-template-columns: auto;
}

.topics-container-header{
    grid-column: 1 / -1;
    grid-row: 1 / 2;

    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 16px;

    position: sticky;
    z-index: 988;
    padding-top: 24px;
    padding-bottom: 16px;
    background-color: white;
}

.topic-searchbox-container {
    flex: 1 0;
    max-width: 288px;

    height: max-content;
    /* background-color: var(--layer-01, #f4f4f4); */
    /* padding: 6px 4px 2px 10px; */
    border-bottom: 1px solid var(--border-strong-01, #8d8d8d);

    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    row-gap: 6px;
}

.topic-searchbox-label {
    /* padding: 0px 4px 2px 0px; */
    font-size: 12px;
    line-height: 12px;
    /* margin-bottom: 6px; */
    color: var(--text-secondary, #525252);
}

.topic-search-inputfield{
    padding: 0px 4px 0px 10px;
    background-color: var(--layer-01, #f4f4f4);
    height: 35.5px;
}

.topic-search-inputfield i {
    height: 14px;
    width: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.topic-searchbox-container span{
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 8px;
}

input#topic-searchbox::placeholder {
    font-weight: 300;
}
.topic-searchbox-container svg{
    fill: var(--text-primary, #161616);
}

.topic-searchbox-container input {
    border: none;
    background-color: transparent;
    flex: 1;
    outline: none;
}

.topic-searchbox-container .clear-searchbox-button {
    background: none;
    border: none;
    cursor: pointer;
    margin-right: 0px;

    width: 22px;
    height: 22px;
    text-align: center;
}

.topic-searchbox-container .clear-searchbox-button:hover{
    background-color: var(--layer-hover-01, #e8e8e8);
}

.topic-searchbox-container input:placeholder-shown + .clear-searchbox-button{
    visibility: hidden;
} 

.topic-searchbox-result {
    color: var(--text-helper,#6f6f6f);
    font-size: 12px;
    flex-basis: 100%;
    /* padding-bottom: 28px; */
}

/* ------------ Right ToC Styling ---------------- */

.right-toc-container{
    grid-column: 2/3;
    padding-top: 32px;

    height: max-content;
    right: 0px;
    top: 0px;
    position: sticky;
}

li.right-toc-item {
    list-style: none;
}

li.right-toc-item a {
    font-size: 14px;
    text-decoration: none;
    color: var(--text-primary,#161616);
    padding: 16px;
    display: block;
    border-left: solid var(--border-subtle-01,#c6c6c6) 4px;
}

li.right-toc-item.active a{
    border-color: var(--border-interactive, #0f62fe);
    font-weight: bold;
}

/* ------------ Version Picker Styling ----------- */

.prev-version-toc-item{
    /* height: 148px;
    padding: 20px; */
    height: 80px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: end;
    padding: 20px;
    padding-bottom: 30px;
}

.version-dropdown-container{
    width: 100%;
    /* max-width: 540px; */

    display: -webkit-flex;
    display: flex;
    column-gap: 4px;
    flex-wrap: wrap;
    row-gap: 8px;
}

.version-info-message{
    display: none;
}

.document-category-dropdown-container{
    max-width: 313px;
    display: none;
}

/* ---------------- Responsive Styling ---------------- */

@media screen and (min-width: 1485px) { 
    .topics-container{
        padding-left: 74px;
    }
}

@media screen and (max-width: 1425.7px) {
    .topics-container{
        padding-left: 74px;
    }
}

@media screen and (max-width: 1215.7px) {
    .topics-container{
        padding-left: 40px;
    }
}

@media screen and (max-width: 1111.7px) {
    .topics-container{
        padding-left: 45px;
    }
}

@media screen and (max-width: 901px){
    ul.breadcrumb-list{
        width: 100;
    }
}

@media screen and (max-width: 771px){
    .topics-container-header{
        top: 102px;
        width: 288px;
    }
    .topics-toc{
        display: none;
    }

    .document-category-dropdown-container{
        display: block;
    }

    #content-section{
        display: -webkit-flex;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .topics-container{
        width: 100%;
        padding-top: 20px;
        padding-left: min(5vw, 40px);
        padding-right: min(5vw, 40px);
    }

    .product-landing-wrapper{
        width: 98vw !important;
        max-width: min(98vw,1800px) !important ;
    }

    .documentation-category-list{
        width: 288px;
    }

    .topics-container-header h2{
        padding-top: 12px;
    }

    .right-toc-container{
        display: none;
    }
}
@media screen and (max-width: 471.5px){
    .product-landing-wrapper{
        width: 92vw !important;
        max-width: min(92vw,1800px) !important ;
    }

    .topics-container-header{
        top: 132px;
    }
}
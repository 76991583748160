.not-found-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.not-found-page .content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  padding: 20px;
  text-align: left;
  padding: max(6vw, 20px);
  column-gap: 64px;
  padding-top: 120px;
}

.not-found-page .text-container {
  flex: 1;
  padding-left: 50px;
  padding-top: 60px;
  max-width: max-content;
  /* margin-top: 160px; */
}

.not-found-page .title {
  font-size: 85px;
  font-weight: 600;
}

.not-found-page .subtitle{
  font-size: 50px;
  font-weight: 500;
}

.not-found-page .instructions{
  font-size: 14px;
}

.not-found-page .image-container {
  flex: 1;
  display: flex;
  justify-content: center;
}

.not-found-page .image-container img {
  max-width: 480px; 
  height: auto;
}

.not-found-page .call-to-action{
  margin: 24px 0;
}

/* Main buttons container */
.not-found-page .cta-buttons {
  display: flex;
  justify-content: center;
  column-gap: 20px;
  row-gap: 14px;
  margin-top: 10px;
}

/* Button styles */
.not-found-page .feedback-btn,
.not-found-page .search-btn {
  padding: 12px 20px;
  font-size: 14px;
  border: none;
  cursor: pointer;
  width: 220px;  /* Fixed width */
  height: 45px;  /* Fixed height */

  display: flex;
  justify-content: center;
  align-items: center;
}

.not-found-page .feedback-btn {
  background-color:transparent;
  color: var(--button-tertiary,#0f62fe);
  border: 1px solid var(--button-tertiary,#0f62fe);
}

.not-found-page .search-btn {
  background-color: var(--button-primary,#0f62fe);
  color: white;
}

.not-found-page .home-btn {
  background-color: transparent;
  color: var(--button-tertiary,#0f62fe);
  text-decoration: underline;
  cursor: pointer;
  margin: 18px 0;
  border: none;
}

/* Hover effects */
.not-found-page .feedback-btn:hover{
  background-color:var(--button-tertiary-hover,#0353e9);
  color: white;
}

.not-found-page .search-btn:hover {
background-color: var(--button-primary-hover,#0353e9);
}

/* Responsive design for mobile */
@media (max-width: 500px) {
  .not-found-page .content {
    flex-direction: column-reverse;
    align-items: center; 
    text-align: center;
    row-gap: 40px;
  }

  .not-found-page .text-container {
    padding-right: 40px;
    /* margin-top: 40%; */
    text-align: left;
  }

  .not-found-page .image-container {
    display: none;
  }

  .not-found-page .image-container img {
    height: 70vw;
    width: auto;
  }

  .not-found-page .feedback-btn, 
  .not-found-page .search-btn {
    height: 45px; /* Adjust height for mobile */
  }
}

@media (max-width: 1050px) {
  .not-found-page .image-container{
    display: none;
  }
}

@media (max-width: 626px) {
  .not-found-page .cta-buttons{
    flex-direction: column-reverse;
  }
}
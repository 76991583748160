.search-quickfilter-wrapper {
    margin-bottom: 8px;
}

.quickfilter-container-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0px 6px;
}

.quickfilter-container-header:hover{
    cursor: pointer;
    background: var(--background-hover, rgb(141, 141, 141, 12%));
}

span.quickfilter-container-title {
    font-size: 12px;
    display: block;
}

.applied-filter-count{
    font-weight: bold;
}

.quickfilter-toggle-btn{
    background: transparent;
    height: 24px;
    width: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;
    transform: rotateZ(180deg);
    -webkit-transform: rotateZ(180deg);
    -moz-transform: rotateZ(180deg);
    -ms-transform: rotateZ(180deg);
    -o-transform: rotateZ(180deg);
}

.expanded .quickfilter-toggle-btn{
    transform: rotateZ(0deg);
    -webkit-transform: rotateZ(0deg);
    -moz-transform: rotateZ(0deg);
    -ms-transform: rotateZ(0deg);
    -o-transform: rotateZ(0deg);
}

ul.quickfilter-list {
    display: flex;
    flex-wrap: wrap;
    row-gap: 4px;
    column-gap: 4px;
    margin-top: 6px;
}
.dropdown-wrapper {
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 96px;
    row-gap: 6px;
}

.dropdown-wrapper.disabled{
    cursor: not-allowed;
    opacity: 60%;
    filter: brightness(0.9);
}

.dropdown-wrapper.disabled *{
    pointer-events: none !important;
}

.dropdown-wrapper.disabled .list-box__menu-icon{
    visibility: hidden;
}

.dropdown-label{
    line-height: 12px;
    font-size: 12px;
    color: var(--text-secondary, #525252);
}

.dropdown-container{
    outline: 2px solid transparent;
    outline-offset: -2px;
    position: relative;
    border: none;
    background-color: var(--field-01,#f4f4f4);

    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
    justify-content: center;
    align-items: flex-start;

    width: 100%;
    height: 36px;
    padding: 2px 12px 0px;

    /* -webkit-border-after: 1px solid var(--cds-border-strong); */
    border-bottom: 1px solid var(--border-strong-01, #8d8d8d);
    color: var(--cds-text-primary,#161616);

    cursor: pointer;
    list-style: none;

    transition: background-color 70ms cubic-bezier(.2,0,.38,.9);
    -webkit-transition: background-color 70ms cubic-bezier(.2,0,.38,.9);
    -moz-transition: background-color 70ms cubic-bezier(.2,0,.38,.9);
    -ms-transition: background-color 70ms cubic-bezier(.2,0,.38,.9);
    -o-transition: background-color 70ms cubic-bezier(.2,0,.38,.9);
}

.dropdown-container .list-box_field{
    width: 100%;
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    align-items: center;
    justify-content: space-between;
    border: none;

    font-size: 14px;
    cursor: pointer;
    background-color: transparent;
}

span.selected_option_label {
    display: flex;
    flex-direction: row;
    align-items: center;
    column-gap: 12px;
}

.dropdown-container .list-box_field .list-box__menu-icon{
    width: 16px;
    height: 16px;
    margin-top: 1px;
    margin-bottom: 2px;
    transform-origin: center;

    transition: transform 150ms linear;
    -webkit-transition: transform 150ms linear;
    -moz-transition: transform 150ms linear;
    -ms-transition: transform 150ms linear;
    -o-transition: transform 150ms linear;
}

.dropdown-container.list-box--expanded .list-box_field .list-box__menu-icon{
    transform: rotateZ(180deg);
    -webkit-transform: rotateZ(180deg);
    -moz-transform: rotateZ(180deg);
    -ms-transform: rotateZ(180deg);
    -o-transform: rotateZ(180deg);
}

.dropdown-container:hover {
    background-color: #e8e8e8;
}

/* .dropdown-container .list-box_field:focus,
.dropdown-container .list-box_field:focus-visible{
    outline: 2px solid var(--cds-focus, #0f62fe);
    outline-offset: -2px;
} */

.dropdown-container .list-box__menu {
    list-style: none;
    
    box-shadow: 0 2px 6px var(--cds-shadow,rgba(0,0,0,0.3));
    position: absolute;
    z-index: 9100;
    top: 36px;
    margin-top: 1px;

    min-width: max-content;
    width: 100%;
    max-width: 288px;
    max-height: min(50vh, 250px);

    /* background-color: #f4f4f4; */
    inline-size: 100%;
    inset-inline-end: 0;
    inset-inline-start: 0;
    overflow-y: auto;
    
    transition: max-height 110ms cubic-bezier(.2,0,.38,.9);
    -webkit-transition: max-height 110ms cubic-bezier(.2,0,.38,.9);
    -moz-transition: max-height 110ms cubic-bezier(.2,0,.38,.9);
    -ms-transition: max-height 110ms cubic-bezier(.2,0,.38,.9);
    -o-transition: max-height 110ms cubic-bezier(.2,0,.38,.9);

    display: none;
}

.list-box--expanded .list-box__menu {
    display: block;
}

.list-box__menu-item {
    font-size: var(--cds-body-compact-01-font-size,0.875rem);
    font-weight: var(--cds-body-compact-01-font-weight,400);
    line-height: var(--cds-body-compact-01-line-height,1.28572);
    letter-spacing: var(--cds-body-compact-01-letter-spacing,0.16px);
    position: relative;
    
    height: 40px;
    max-width: 288px;

    color: var(--text-secondary,#525252);
    background-color: var(--field-01,#f4f4f4);
    
    transition: background 70ms cubic-bezier(.2,0,.38,.9);
    
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    cursor: pointer;
}

.list-box__menu-item__option {
    box-sizing: border-box;
    padding: 0;
    border: 0;
    margin: 0;
    font-family: inherit;
    outline: 2px solid transparent;
    outline-offset: -2px;
    display: block;
    height: 40px;

    border-bottom: solid 1px var(--border-subtle-01,#c6c6c6);
    color: var(--text-secondary,#525252);
    font-weight: 400;
    line-height: 16px;
    text-decoration: none;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    
    -o-transition: border-color 70ms cubic-bezier(.2,0,.38,.9),color 70ms cubic-bezier(.2,0,.38,.9);
    -ms-transition: border-color 70ms cubic-bezier(.2,0,.38,.9),color 70ms cubic-bezier(.2,0,.38,.9);
    -moz-transition: border-color 70ms cubic-bezier(.2,0,.38,.9),color 70ms cubic-bezier(.2,0,.38,.9);
    -webkit-transition: border-color 70ms cubic-bezier(.2,0,.38,.9),color 70ms cubic-bezier(.2,0,.38,.9);
    transition: border-color 70ms cubic-bezier(.2,0,.38,.9),color 70ms cubic-bezier(.2,0,.38,.9);
}

.list-box__menu-item:hover {
    background-color: #e8e8e8;
}

/* .list-box__menu-item--highlighted, */
.list-box__menu-item:has(> .list-box__menu-item__option .selected) {
    outline: 2px solid var(--focus,#0f62fe);
    outline-offset: -2px;
    color: var(--text-primary,#161616);
}

.list-box__menu-item__option.no-link,
.list-box__menu-item__option a{
    display: flex;
    align-items: center;
    height: 100%;
    column-gap: 8px;

    padding: 12px 0;
    margin: 0 16px;

    overflow: hidden;
    text-overflow: ellipsis;
    text-decoration: none;
    color: inherit;
}

.dropdown-helptext{
    color: var(--text-helper, #6f6f6f);
    font-size: 12px;
    display: flex;
    flex-direction: row;
    column-gap: 4px;
    margin-top: 8px;
    margin-bottom: 8px;

    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.dropdown-helptext a,
.dropdown-helptext a:visited{
    color: var(--link-primary, #0f62fe);
}

.dropdown-helptext a:hover{
    color: var(--link-primary-hover, #0043ce);
}

@media screen and (max-width: 771px){
    .dropdown-wrapper{
        flex: 1;
        /* flex-basis: 100%; */
    }

    .dropdown-helptext{
        font-size: 10px;
    }

    /* .dropdown-label{
        display: none;
    } */
}

